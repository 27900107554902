<template>
  <section id="Equipo" class="team" data-aos="fade-down">
    <div class="__content">
      <h2><b>{{ data.title }}</b></h2>
      <div class="___team-container">
        <div class="tarjeta-equipo py-5 px-3" v-for="(card, i) in data.cards" :key="card.id">
          <div class="d-flex justify-content-center container-equipo-experiencia">
            <img
              class="img-equipo-experiencia"
              :src="require(`../../assets/${card.img}`)"
              :alt="card.name"
              loading="lazy"
            />
          </div>
          <div class="size-nombre-testimonial">
            <a
              :href="card.linkedIn"
              target="_blank"
              style="text-decoration: none; color: #000"
            >
              <h6 class="sub-titulo fw-bold text-center mb-4">
                <img
                  height="18"
                  src="@/assets/img/icono_linkedin.svg"
                  style="margin-bottom: 4px; margin-right: 6px"
                  alt="Icono LinkedIn"
                  loading="lazy"
                />{{ card.name }}
              </h6>
            </a>
            <p class="body2 text-center mb-0" :id="`person${i}`"> {{ card.preview }}</p>
          </div>
          <div class="accordion accordion-flush" :id="`accordionCardExample${i}`">
            <div class="">
              <h2 class="accordion-header" :id="`heading${i}`">
                <button class="accordion-button my-3 collapsed" type="button" data-bs-toggle="collapse"
                        :data-bs-target="`#collapse${card.htmlId}`" aria-expanded="true"
                        :aria-controls="`collapse${card.htmlId}`">
                  <!--                  <i class="bi bi-caret-down"></i>-->
                </button>
              </h2>
              <div :id="`collapse${card.htmlId}`" class="accordion-collapse collapse"
                   :aria-labelledby="`heading${card.htmlId}`" :data-bs-parent="`accordionCardExample${i}`">
                <div class="accordion-body">
                  <div v-html="card.content"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import store from '../../store';

export default {
  name: "Equipo",
  data() {
    return {
      data: {
        title: 'Equipo',
        cards: [
          {
            id: 1,
            htmlId: "edgar",
            img: "img/equipo_edgar.webp",
            name: "Edgar Witt G.",
            preview: `Ejecutivo con vasta experiencia en la gestión de empresas multinacionales tecnológicas. Empresario
           con experiencia en diversas industrias: Tecnologías de la información, Salud, Inmobiliaria, Seguridad y Turismo.`,
            content: `
            <p>Más de 25 años liderando multinacionales tecnológicas de los cuales 16 años lo hace como Gerente General. Actualmente participa del mundo de la innovación tecnológica y de la transformación de modelos de negocio en diversas industrias, participando activamente con su experiencia en directorios y como advisor de empresas. <br><br>Ingeniero Civil UCH.</p>
          `,
            linkedIn: "http://linkedin.com/in/edgar-witt-ba1977",
            email: "ewitt@wetforest.com",
          },
          {
            id: 2,
            htmlId: "drago",
            img: "img/equipo_drago.webp",
            name: "Drago Eterovic M.",
            preview: `Empresario que ha creado mas de 8 empresas exitosas en las áreas de desarrollo de software de
          gestión tecnológica de la información de servicios, consultoría y educación.`,
            content: `
            <p>Socio y Gerente de importantes compañias extranjeras de las áreas de Equipos TIC, Software de Gestión y Equipamiento Médico. Lideró la expansión de varias empresas tecnológicas internacionales de Latinoamerica, ha sido Director de empresas financieras y de seguros. <br><br>Director de empresas. <br>Ingeniero Civil UCH. <br>Postgrado en Marketing UCH. <br>Estudios de Director de Empresas en Intitute of Directors (IOD) en Chile e Inglaterra.</p>
          `,
            linkedIn: "linkedin.com",
            email: "deterovic@wetfores.com",
          },
          {
            id: 3,
            htmlId: "patricio",
            img: "img/equipo_patricio.webp",
            name: "Patricio Torm B.",
            preview: `Empresario que ha creado mas de 13 empresas exitosas innovadoras en el campo de las tecnologías
           de la información, comunicaciones, seguridad y consultoría.`,
            content: `
            <p>Se mantiene innovando permanentemente apoyando a construir y transformar empresas e invirtiendo en nuevos emprendimientos a través de TKNS Ventures. <br> <br>Director de empresas. <br>Ingeniero Civil UCH.</p>
          `,
            linkedIn: "linkedin.com",
            email: "ptorm@wetforest.com",
          },
        ],
      }
    }
  },
  mounted() {
    this.fetchData();
  },
  watch: {
    "$store.state.sections": function() {
      this.fetchData();
    }
  },
  methods: {
    fetchData() {
       if (store.state.sections.team) {
      this.data = store.state.sections.team.components}
    }
  }
}
</script>

<style scoped lang="scss">

.team {
  align-items: center;
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 20px;

  .__content {
    width: 100%;
    max-width: 1366px;
    position: relative;
  }

  .__content .___team-container {
    display: grid;
    grid-gap: 144px 24px;
    grid-template-columns: 1fr;
    grid-auto-rows: auto;
    margin: 125px 0;
  }
}

.Equipo {
  position: relative;
}

.container-wetforest {
  padding-left: 8%;
  padding-right: 8%;
}

.sub-titulo {
  color: #414141;
  font-family: "InterMedium";
  font-size: 1.5rem;
  text-align: left;
}

.titulo {
  color: #414141;
  font-family: "GentiumBold";
  font-size: 3rem;
  text-align: left;
  margin-bottom: 5rem;
}

.tarjeta-equipo {
  background-color: #fff;
  border-radius: 6px;
  min-height: 340px;
  border: 1px solid #cae6d3;
  box-shadow: 0px 3px 6px #14993c65;
  /*padding-bottom: 1rem;*/
  /*padding-top: 5rem;*/
  /*padding-left: 1rem;*/
  /*padding-right: 1rem;*/
  position: relative;
}

.column {
  margin-bottom: 5rem;
}

.container-equipo-experiencia {
  margin-top: -7rem;
  /*position: absolute;*/
  /*left: 50%;*/
  /*top: -70px;*/
}

.img-equipo-experiencia {
  width: 140px;
  /*left: -50%;*/
  position: relative;
}

.size-nombre-testimonial {
  min-height: 70px;
}

.accordion {
  overflow: hidden;
  transition: height 0.35s ease;
}

tarjeta-equipo .accordion-item {
  border: none !important;
}

.tarjeta-equipo .accordion-button {
  border: 1px solid #27ba54 !important;
  border-radius: 10px !important;
  padding: 0.2rem;
  width: 140px;
}

.tarjeta-equipo .accordion-header {
  display: flex;
  justify-content: center;
}

.tarjeta-equipo .accordion-button::after {
  width: 1rem !important;
  height: 1rem !important;
  background-size: 1rem !important;
  margin-right: auto;

}

.tarjeta-equipo .accordion-body {
  padding: 0px !important;
  padding-top: 1rem !important;
}

.tarjeta-equipo .accordion-button:not(.collapse)::after {

}

@media (min-width: 992px) {
  .team {
    padding: 0;

    .__content .___team-container {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;

      .tarjeta-equipo {
        width: calc(33% - 24px);
      }
    }
  }
}

</style>
